import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'



export const getAdminContent = createAsyncThunk('/admin/content', async () => {
	const response = await axios.get('/api/users?page=2', {})
	return response.data;
})

export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        isLoading: false,
        admin : []
    },
    reducers: {


        addNewAdmin: (state, action) => {
            let {newAdminObj} = action.payload
            state.admin = [...state.admin, newAdminObj]
        },

        deleteAdmin: (state, action) => {
            let {index} = action.payload
            state.admin.splice(index, 1)
        }
    },

    extraReducers: {
		[getAdminContent.pending]: state => {
			state.isLoading = true
		},
		[getAdminContent.fulfilled]: (state, action) => {
			state.admin = action.payload.data
			state.isLoading = false
		},
		[getAdminContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export const { addNewAdmin, deleteAdmin } = adminSlice.actions

export default adminSlice.reducer